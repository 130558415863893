.section-hero
  display: grid
  text-align: center
  height: 100vh

  .bg
    position: absolute
    padding: 0
    margin: 0

    height: -moz-available
    height: -webkit-fill-available

    width: 100%
    object-fit: cover
    z-index: 1
    opacity: 0.3
    @media only screen and (min-width: $tabletBreakpoint)
      height: 100vh
  .layout
    display: grid
    z-index: 2

    height: -moz-available
    height: -webkit-fill-available

    grid-template-columns: 1fr
    grid-template-rows: 1fr min-content
    grid-template-areas: "bubble""illustration"

    @media only screen and (min-width: $tabletBreakpoint)
      height: 100vh
      grid-template-columns: 1fr 1fr
      grid-template-rows: 1fr
      grid-template-areas: "bubble illustration"

  .bubble
    grid-area: bubble
    align-self: center
    justify-self: center
    margin-top: 2em
    @media only screen and (min-width: $tabletBreakpoint)
      align-self: flex-end
      justify-self: flex-end
      margin-bottom: 20em

.hero-container
  position: relative
  height: 80vh
  @media only screen and (min-width: $desktopBreakpoint)
    height: 95vh

@keyframes slide-in-r
  0%
    left: 0
    opacity: 0
  30%
    opacity: 0
  100%
    width: 100%

@keyframes slide-in-r-2
  0%
    left: 0
  30%
    opacity: 0
  100%
    width: 100%

.bg-0-r
  animation: slide-in-r .6s
.bg-1-r
  animation: slide-in-r-2 .6s

.bg-0-l
  animation: slide-in-l .6s
.bg-1-l
  animation: slide-in-l-2 .6s

@keyframes slide-in-l
  0%
    left: 100%
    opacity: 0
  30%
    opacity: 0
  100%
    width: 100%

@keyframes slide-in-l-2
  0%
    left: 100%
    opacity: 0
  30%
    opacity: 0
  100%
    width: 100%

#bg-overlay
  position: absolute
  bottom: 0
  z-index: 0
  height: 100px
  width: 100vw
  background: linear-gradient(180deg, #00000000, #00000080)

#bg-overlay-full
  opacity: 0.85
  height: 100%
  width: 100%
  position: absolute
  background-color: #a4508b
  background-image: linear-gradient(109.6deg, rgb(84, 13, 110) 11.2%, rgb(238, 66, 102) 100.2%)

.hero-buttons
  position: absolute
  bottom: 0
  width: 100%
  display: flex
  justify-content: space-between
  padding: 1em

  button
    transition: .2s
    color: white

    &:hover
      transition: .2s ease-in
      transform: scale(1.1,1.2)

.illustration
  z-index: 2
  position: absolute
  bottom: 0
  right: 50px
  height: 250px

  @media only screen and (min-width: $tabletBreakpoint)
    height: 500px
