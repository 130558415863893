.caret-container
    background: #ffffff
    position: fixed
    top: 70px
    right: 1.5em
    min-width: 200px
    border-radius: 0 0 .5em .5em
    box-shadow: 2px 2px 12px #00000020
    z-index: 10
    transition: .3s
    button
        width: 100%
    &:hover
        background: #ffffff
    @media only screen and (max-width: 420px)
        right: .5em

#btn-opener
    display: grid
    align-items: center
    grid-template-columns: 1fr min-content
    border-bottom: 1px solid #00000010
    height: 44px

    h3,h4,p
        justify-self: flex-start
        padding-left: .5em

    #arrow-up
        animation: rotate-down .3s
    #arrow-down
        animation: rotate-up .3s
.caret
    display: flex
    flex-direction: column
    height: 0
    overflow: hidden
    height: 100%

    li
        height: 44px

    li:hover
        background: #00000010

    button
        transition: .15s ease-in
        height: 100%

    .active
        font-weight: 600
        transition: .15s ease-in

.caret-open
    max-height: 300px
    transition: max-height .3s ease-in

.caret-close
    max-height: 0px
    transition: max-height 0.3s ease-out
@keyframes rotate-up
    from
        -webkit-transform: rotate(-180deg)
    to
        -webkit-transform: rotate(0deg)

@keyframes rotate-down
    from
        -webkit-transform: rotate(180deg)
    to
        -webkit-transform: rotate(0deg)
