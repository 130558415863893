html
  scroll-behavior: smooth
  overflow-x: hidden

.App
  display: grid
  grid-auto-flow: row
  min-height: -webkit-fill-available
  background: #f5f5f5100

.accordions
  padding: 1em .5em
  display: grid
  row-gap: 1em
  @media only screen and (min-width: $tabletBreakpoint)
    padding: 3em 2em

// n is number of stars required
@function generate-box-shadow($n)
  $value: '#{random(1000)}px #{random(1000)}px white'
  @for $i from 2 through $n
    $value: '#{$value} ,#{random(1200)}px #{random(500)}px 20px 5px white'
  @return unquote($value)

@function create-box-shadow($n)
  $value: '#{random(1000)}px #{random(1000)}px white'
  @for $i from 2 through $n
    $value: '#{$value} ,#{random(1200)}px #{random(500)}px 1px 0 white'
  @return unquote($value)

@keyframes shake
  0%
    transform: translateY(0px)
  50%
    transform: translateY(20px)
  100%
    transform: translateY(0px)

@keyframes glow
  0%
    transform: translateY(0px) scale(1)
  50%
    transform: translateY(20px) scale(.95)
  100%
    transform: translateY(0px) scale(1)
.bigimage
  position: relative
  // overflow: hidden

  #stars-down
    position: absolute
    z-index: 4000
    bottom: 440px
    left: 30%

    width: 3px
    height: 3px
    border-radius: 5px

    background: transparent
    box-shadow: generate-box-shadow(40)
    animation: shake 3s linear infinite

  #stars-down-core
    position: absolute
    z-index: 4000
    bottom: 340px
    left: 50%

    width: 5px
    height: 5px
    border-radius: 5px

    background: transparent
    box-shadow: create-box-shadow(20)
    animation: glow 5s linear infinite

  img
    opacity: .5
    height: 500px
    width: 100%
    overflow: hidden
    opacity: .7
    filter: brightness(.9)
    object-fit: cover
    object-position: bottom
    @media only screen and (max-width: $tabletBreakpoint)
      height: 300px
      object-position: bottom right
