.section-portfolio
  display: flex
  justify-content: center
  align-items: center

  .wrapper
    width: 100%
    padding: 1em
    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: repeat(3, min-content)
    grid-template-areas: "paragraph paragraph paragraph""toggle toggle toggle""data data data"

    @media only screen and (min-width: $tabletBreakpoint)
      padding-left: 3em
      grid-template-columns: repeat(3, 1fr)
      grid-template-rows: 120px min-content
      grid-template-areas: "paragraph data data""toggle data data"

    .toggle-gallery
      grid-area: toggle
      align-self: flex-start
      margin-top: 1em

    h2
      grid-area: title
      width: 100%

    p
      grid-area: paragraph
      padding: 1em
      line-height: 1.4
      justify-self: flex-start
      border-left: 1em solid #eeeeee
      padding-left: 2em

      @media only screen and (min-width: $tabletBreakpoint)
        height: min-content
        margin-top: 36px

    .projects-lists
      grid-area: data
      margin: 20px 0
      padding: 0

      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 1em
      justify-items: center
      align-items: center

      @media only screen and (min-width: $tabletBreakpoint)
        margin: 20px
        grid-template-columns: repeat(3, 1fr)
        gap: 10px
      @media only screen and (min-width: $XLBreakpoint)
        grid-template-columns: repeat(4, 1fr)

    .classic,.creative
      transition: .1s ease

    .creative
      display: grid
      width: 100%
      gap: 1em
      grid-template-columns: repeat( auto-fill, minmax( 120px, 1fr ) )
      grid-auto-rows: 30px
      li .card
        position: relative
        height: 100%
        width: 100%

        display: block
        .card-img
          border-radius: .3em
          img
            filter: brightness(.8)

        h3
          position: absolute
          top: 0
          left: .3em
          height: min-content
          color: $white
          text-shadow: 2px 2px 3px rgba(0,0,0,.3)

      li:nth-child(n)
        height: 100%
        grid-row-end: span 3

      li:nth-child(2n+1)
        height: 100%
        grid-row-end: span 4

      li:nth-child(2n+3)
        height: 100%
        grid-row-end: span 5
