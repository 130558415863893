a,
a:visited,
a:active
  color: inherit
  text-decoration: inherit

h1,
h2,
h2,
h3,
h4,
p,
textarea,
input,
option
  margin: 0
  padding: 0
  font-family: 'Poppins', sans-serif
  color: $grayText

h1
  font-size: 26px
  font-weight: 500
  line-height: 41px

  @media only screen and (min-width: $tabletBreakpoint)
    font-size: 30px
    line-height: unset

h2

  font-size: 19px
  font-weight: 500
  line-height: 28px

  @media only screen and (min-width: $tabletBreakpoint)
    font-size: 19px
    line-height: unset

h3

  font-size: 16px
  font-weight: 500
  line-height: 22px

  @media only screen and (min-width: $tabletBreakpoint)
    font-size: 16px
  @media only screen and (max-width: $tabletBreakpoint)
    font-size: 14px

h4,input,button,label,textarea

  font-size: 13px
  font-weight: 500
  line-height: 17px

  @media only screen and (min-width: $tabletBreakpoint)
    font-size: 13px
    line-height: unset

p

  font-size: 13px
  font-weight: 400
  line-height: 17px
  strong
    font-weight: 600

  @media only screen and (min-width: $tabletBreakpoint)
    font-size: 13px
    line-height: unset

ul
  list-style-type: none
