.modal-overlay
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: rgba(0, 0, 0, 0.7)
  z-index: 1000

.modal-wrapper
  position: fixed
  width: 90vw
  height: 80vh
  transform: translate(-50%, -50%)
  background: white
  overflow: hidden
  top: 50%
  left: 50%
  border-radius: .5em
  z-index: 1000
  @media only screen and (min-width: $desktopBreakpoint)
    height: 80vh

.modal

  height: 80vh

  display: grid
  align-items: center
  grid-template-rows: min-content min-content min-content 1fr min-content min-content
  grid-template-columns: 1fr
  grid-template-areas: "data ""title ""pills ""description " "mq" "buttons"

  @media only screen and (min-width: $desktopBreakpoint)
    grid-template-rows: min-content min-content 1fr min-content min-content
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr .4fr
    grid-template-areas: "data data data data title none""data data data data pills pills""data data data data description description ""data data data data mq mq ""data data data data buttons buttons "
    gap: .5em
    padding: 0
    height: 80vh

  .modal-img
    grid-area: data
    overflow: hidden
    filter: brightness(.9)
    @media only screen and (min-width: $desktopBreakpoint)
      border-right: 1px solid $grayLight

    img
      height: 250px
      width: 100%
      object-fit: cover
      object-position: top
      @media only screen and (min-width: $desktopBreakpoint)
        height: 81vh
        object-position: top left

  h1,h2
    grid-area: title
    line-height: 45px
    padding: 0 .6em
    @media only screen and (min-width: $desktopBreakpoint)
      padding: 1em .3em

  p
    overflow: hidden
    grid-area: description
    align-self: flex-start
    padding: 0 1.2em
    @media only screen and (min-width: $desktopBreakpoint)
      padding: 0 1em

  .pills-container
    grid-area: pills
    display: flex
    flex-direction: column
    justify-content: flex-start
    padding: 0
    margin: 0 15px
    @media only screen and (min-width: $desktopBreakpoint)
      margin: 0 0 0 10px

    .pills
      margin: 0
      padding: .2em 0
      height: min-content

      display: flex
      flex-wrap: wrap
      justify-content: flex-start

      li img
        height: 24px
        margin: 0 2px

  .mq-container
    grid-area: mq

    padding: 0 16px

    display: flex
    justify-content: center
    align-content: flex-end

    color: #222
    .mq-item
      position: relative
      margin-right: 2px
      margin-left: 2px
      cursor: pointer

      p
        position: absolute
        top: -20px
        left: 50%
        transform: translateX(-50%)
        opacity: 0

        margin: 0
        padding: 0

        transition: opacity .3s

      &:hover
        color: $blue
        p
          opacity: 1
          transition: opacity .3s
          color: $blue

  .buttons

    grid-area: buttons
    padding: 0 1em 1em 1em

    margin: 0
    display: flex
    flex-direction: column
    justify-content: space-evenly
    @media only screen and (min-width: $desktopBreakpoint)
      justify-content: flex-end

    .btn-main,
    .btn-ghost
      width: 100%
      margin: .3em 0

  .btn-close
    position: absolute
    top: 10px
    right: 10px
    z-index: 4000

    display: flex
    justify-content: flex-end
    align-items: center
    padding-right: 1em
    border-radius: 30px
    transition: .3s
    margin: 0
    padding: 0

    background: black
    color: white
    &:hover
      scale: 1.2
      transform: rotate(-90deg)
      transform-origin: center center
      transition: .3s

    @media only screen and (min-width: $desktopBreakpoint)
      background: none
      color: black

//Animations
//in
.modal-overlay
  animation: unfold-in .6s
.modal-wrapper
  animation: zoom-in 1.2s

//out 
.modal-out
  animation: zoom-out 1s
.overlay-out
  animation: unfold-out 1s

@keyframes unfold-in
  0%
    transform: scaleY(.005) scaleX(0)
  50%
    transform: scaleY(.005) scaleX(1)
  100%
    transform: scaleY(1) scaleX(1)

@keyframes unfold-out
  0%
    transform: scaleY(1) scaleX(1)
  50%
    transform: scaleY(1) scaleX(1)
  75%
    transform: scaleY(.005) scaleX(1)
  100%
    transform: scaleY(.005) scaleX(0)

@keyframes zoom-in
  0%
    transform: translate(-50%, -50%) scale(.5,.5)
    opacity: 0
  50%
    transform: translate(-50%, -50%) scale(.5,.5)
    opacity: 0
  100%
    transform: translate(-50%, -50%) scale(1,1)
    opacity: 1

@keyframes zoom-out
  0%
    transform: translate(-50%, -50%) scale(1,1)
    opacity: 1
  50%
    transform: translate(-50%, -50%) scale(.5,.5)
    opacity: 0
  100%
    transform: translate(-50%, -50%) scale(.5,.5)
    opacity: 0
