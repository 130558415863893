.section-technologies
  display: flex
  justify-content: center
  align-items: center

  .wrapper
    grid-template-columns: 1fr
    grid-template-areas: unset
    gap: 0
    width: 100%
    padding: 1em
    @media only screen and (min-width: $tabletBreakpoint)
      padding: 3em

    p
      border-left: 1em solid #eeeeee
      padding-left: 2em
      justify-self: flex-start

    .techno-grid
      display: grid
      grid-template-columns: 1fr 1fr
      width: 100%
      padding: 2em 0
      justify-items: center
      .techno-item
        width: 125px
        height: 125px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        a
          width: 100%
          height: 100%
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
          border-radius: 2000px
          transition: .5s

        a:hover
          background: #e5e5e5
          transition: .5s

        img
          height: 50px

        p
          padding: 1em
          line-height: 1.4
