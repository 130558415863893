.toggle-theme
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    width: 72px
    height: 32px
    display: inline-block
    position: relative
    border-radius: 50px
    overflow: hidden
    outline: none
    border: none
    cursor: pointer
    background-color: #707070
    transition: background-color ease 0.3s

    &:before
        content: "light dark"
        display: block
        position: absolute
        z-index: 2
        width: 28px
        height: 28px
        background: $grayLight
        left: 2px
        top: 2px
        border-radius: 50%
        font: 10px/28px Helvetica
        text-transform: uppercase

        text-indent: -35px
        word-spacing: 37px
        color: $grayLight
        white-space: nowrap
        box-shadow: 0 1px 2px rgba(0,0,0,0.2)
        transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s

    &:checked
        background-color: $yellow

    &:checked:before
        left: 42px
