$configs: (
    chart-French: (
        svgSize: 80px,
        percentage: 100,
        strokeWidth: 12px,
        backgroundColor: #305556,
        foregroundColor: #389967,
        labelColor: #389967,
        labelFontSize: 1rem,
        duration: 3s,
        animationDelay: 1s,
    ),
    chart-English: (
        svgSize: 80px,
        percentage: 80,
        strokeWidth: 12px,
        backgroundColor: #305556,
        foregroundColor: #389967,
        labelColor: #389967,
        labelFontSize: 1rem,
        duration: 3s,
        animationDelay: 1s,
    ),
    chart-Swedish: (
        svgSize: 80px,
        percentage: 70,
        strokeWidth: 12px,
        backgroundColor: #305556,
        foregroundColor: #389967,
        labelColor: #389967,
        labelFontSize: 1rem,
        duration: 3s,
        animationDelay: 1s,
    ),
);

/* ------- DO NOT EDIT BELOW --------- */
$pi: 3.14;
$labelData: '';
@for $i from 0 to 101 {
    $labelData: $labelData + $i + '%' + '\a';
}

@each $chart, $param in $configs {
    $c: (map-get($param, svgSize) - map-get($param, strokeWidth)) * $pi;
    $cOffSet: (map-get($param, percentage) / 100) * $c;

    .#{$chart} {
        width: map-get($param, svgSize);
        height: map-get($param, svgSize);
        margin: 0;
        position: relative;

        &.animate {
            svg .circle-foreground {
                animation: offset map-get($param, duration) ease-in-out forwards;
                animation-delay: map-get($param, animationDelay);
            }
            figcaption:after {
                animation: #{$chart}-label map-get($param, duration) steps(map-get($param, percentage)) forwards;
                animation-delay: map-get($param, animationDelay);
            }
        }

        svg {
            width: 100%;
            height: 100%;
            .circle-background {
                r: (map-get($param, svgSize) - map-get($param, strokeWidth))/2;
                cx: 50%;
                cy: 50%;
                fill: none;
                stroke: map-get($param, backgroundColor);
                stroke-width: map-get($param, strokeWidth);
            }
            .circle-foreground {
                @extend .circle-background;
                stroke: map-get($param, foregroundColor);
                stroke-dasharray: $cOffSet $c;
                stroke-dashoffset: $cOffSet;
                stroke-linecap: round;
                transform-origin: 50% 50%;
                transform: rotate(-90deg);
            }
        }
    }
    @keyframes #{$chart}-label {
        100% {
            transform: translateY(map-get($param, labelFontSize) * (-(map-get($param, percentage))));
        }
    }
}
@keyframes offset {
    100% {
        stroke-dashoffset: 0;
    }
}
