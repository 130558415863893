section
  width: 100%

  .wrapper
    display: grid
    gap: .3em
    align-items: center
    justify-items: center

    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: repeat(2, min-content)
    grid-template-areas: "paragraph paragraph paragraph""data data data"

    @media only screen and (min-width: $desktopBreakpoint)
      grid-template-areas: "title title title""paragraph data data"
