.bubble
    background: $beige
    position: absolute
    left: 5%

    padding: 1.5em 2em
    border-radius: 2em
    box-shadow: 22px 22px $gray5_Shadow
    animation: float2 5s ease-in-out infinite

    @media only screen and (min-width: $desktopBreakpoint)
        padding: 1.5em 3em
        right: calc(400px + 10%)
        left: unset
        bottom: unset

    @media only screen and (max-width: 420px)
        max-width: fit-content
        padding: .75em 1em

    .title
        color: $gray5

    .location
        display: flex
        align-items: end
        p
            font-size: 17px

.bubble:after
    transform: translatey(0px)
    animation: float2 5s ease-in-out infinite
    content: "."
    font-weight: bold

    background: $beige
    color: $beige

    text-shadow: 22px 22px $gray5_Shadow
    text-align: left

    font-size: 55px
    width: 55px
    height: 11px
    line-height: 30px
    border-radius: 11px

    position: absolute
    display: block
    bottom: -30px

    left: 120px
    box-shadow: 22px 22px $gray5_Shadow
    z-index: -2

    @media only screen and (min-width: $tabletBreakpoint)
        left: unset
        right: 0
        text-align: right
