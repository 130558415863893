.nav-container
  position: relative

.header-menu
  position: fixed
  top: 0
  left: 0
  width: 100%
  transition: .3s

  background: $white
  height: $header-height
  display: grid
  grid-template-columns: max-content 1fr
  align-items: center
  padding-left: 4em
  padding-right: .5em

  z-index: 100

  @include shadow-light

  @media only screen and (min-width: $tabletBreakpoint)
    padding-right: 2em
    padding-left: 5em

  @media only screen and (min-width: $desktopBreakpoint)
    display: flex
    justify-content: space-between
    height: $header-height

  .nav-item
    padding: 0
    h3
      transition: .3s
      &:hover
        transition: .3s

.author
  display: flex
  color: $black
  @media only screen and (MAX-width: $tabletBreakpoint)
    h3
      display: none

.bar
  height: $mob-button-height
  width: 100%
  display: grid
  grid-auto-flow: column
  gap: 6px

  justify-content: flex-end
  align-items: center

  @media only screen and (min-width: $desktopBreakpoint)
    background: none
    width: fit-content

  button
    text-align: center
    font-size: 13px
    color: $black
    h3
      text-transform: capitalize
      border-bottom: 3px solid transparent
      &:hover
        border-color: inherit

  .toggle-theme
    transform: scale(.8)
    @media only screen and (min-width: $tabletBreakpoint)
      margin-left: 1em
      transform: scale(1)

.nav-logo
  position: absolute
  border-radius: 60px
  left: 1em

  img
    height: 50px
    width: 50px
    filter: invert(1)

  &:hover
    transform: scale(1.05)
    transition: 0.1s ease
.transparent
  transition: .3s
  opacity: .7
