*
  box-sizing: border-box
  scroll-behavior: smooth
  -ms-overflow-style: none
  font-smooth: auto
  -moz-osx-font-smoothing: auto
  -webkit-font-smoothing: subpixel-antialiased
  scrollbar-width: none

:-webkit-scrollbar
  display: none

ul,
li,
table,
tr,
td
  margin: 0
  padding: 0

// Image sizes

.img-50
  height: 50px
  width: 50px

.img-40
  height: 40px

.img-30
  height: 30px
  width: 30px

.img-bw
  filter: grayscale(.2)

.img-2em
  width: 2em
  height: auto

.img-1em
  width: 1em
  height: auto

textarea:focus, input:focus
  outline: none

.hidden
  display: none

.float-left
  position: fixed
  top: 0
  left: 0
  height: 100px
  width: 100px
  background: red
