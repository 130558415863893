
// n is number of stars required
@function multiple-box-shadow($n)
    $value: '#{random(1000)}px #{random(1000)}px #FFF'
    @for $i from 2 through $n
        $value: '#{$value} ,#{random(2000)}px #{random(2000)}px #FFF'
    @return unquote($value)

$shadows-small: multiple-box-shadow(200)
$shadows-medium: multiple-box-shadow(50)
$shadows-big: multiple-box-shadow(20)

.sky
    // position: absolute
    height: 100vh
    width: 100vw
    z-index: 1

    background: radial-gradient(ellipse at bottom, #1B2735a2 0%, #090a0fa2 70%)
    background-blend-mode: multiply
    overflow: hidden

#moon
    position: absolute

    top: 300px
    left: 80px

    height: 50px
    width: 50px
    border-radius: 50px
    background: #fefefe
    box-shadow: 0px 0px 32px #fefefe
    animation: shine 5s ease-in-out infinite

    @media only screen and (min-width: 500px)
        top: 150px
        left: 180px

#stars
    z-index: 1000
    width: 1px
    height: 1px
    background: transparent
    box-shadow: multiple-box-shadow(700)
    animation: animStar 50s linear infinite

    &:after
        content: " "
        top: 2000px
        width: 1px
        height: 1px
        background: transparent
        box-shadow: $shadows-small

#stars2
    width: 2px
    height: 2px
    background: transparent
    box-shadow: $shadows-medium
    animation: animStar 100s linear infinite

    &:after
        content: " "
        position: absolute
        top: 2000px
        width: 2px
        height: 2px
        background: transparent
        box-shadow: $shadows-medium

#stars3
    width: 3px
    height: 3px
    background: transparent
    box-shadow: $shadows-big
    animation: animStar 150s linear infinite

    &:after
        content: " "
        position: absolute
        top: 2000px
        width: 3px
        height: 3px
        background: transparent
        box-shadow: $shadows-big

@keyframes animStar
    from
        transform: translateY(0px)
    to
        transform: translateY(-1000px)
