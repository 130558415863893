.dark
  transition: .1s ease-in
  background: $gray2

  h1,h2,h3,h4,p
    color: #cacaca

  .header-menu,.accordions,.section-contact
    background: $gray2

  .header-menu img
    filter: invert(0.3)

  .nav-item h3
    color: $white

  .header-transparent
    background: rgba(0,0,0,.5)

  .bg
    filter: grayscale(1) brightness(.1)
  .illustration
    filter: grayscale(.5) sepia(.3)

  .bubble
    $color: $white
    z-index: 1
    background: $color
    box-shadow: 0px 0px 16px $color
    h1,h3
      color: #0c092e

    &::after
      background: $color
      color: $color
      box-shadow: 0px 0px 16px $color
      text-shadow: 0px 0px 16px $color

  .accordion
    .accordion-title
      background: $gray3
      &:hover
        background: $gray4

      h3
        color: $white

    .accordion-content
      background: $gray3
      border-top: 1px solid #ffffff10
    .about-paragraph
      border-color: $gray4

  .section-portfolio .wrapper p, .section-technologies .wrapper p
    border-color: $gray4

  .section-contact .wrapper
    border-color: $gray4
    ul img
      filter: invert(1)
      opacity: .6

  footer
    background: $gray2
    border-top: 1px solid $gray5
    a:hover
      color: $yellow

  .comingsoon
    color: $red

  .carousel-expand
    h3, p
      color: #000

  .card-edu,.card-exp
    filter: brightness(0.8)
    &:hover
      filter: brightness(1)

  .caret-container
    background: $gray2
    &:hover
      background: $gray3
    svg
      fill: white
    button
      color: white
    .caret li:hover
      background: $gray4

  .btn-more
    background: $yellow
    h3,h4,p
      color: $gray2
    &:hover
      background: $yellowLight
