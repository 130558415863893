/* Media queries */
$tabletBreakpoint: 700px
$desktopBreakpoint: 1000px
$XLBreakpoint: 1200px

/** colors **/
$grayDark: #c4c4c4
$grayLighter: #fafafa
$grayLight: #eeeeee
$grayLightDarker: #e4e3e3

$white: white
$black: black

$gray1: #111111
$gray2: #222222
$gray3: #333333
$gray4: #444444
$gray5: #555555
$gray6: #666666
$gray7: #777777
$gray8: #888888
$gray9: #999999
$grayText: #262F36

$gray5_Shadow: rgba(85, 85, 85, 0.349)

$redLight: #f9dddd
$red: #EB5857
$redDark: #b92d2b

$yellow: #eac520
$yellowLight: #eee2ab

$blue: #1489E6
$lightBlue: #56CCF2
$darkBlue: #127bcf

$green: #6FCF97
$greenDark: #27AE60

$pink: #F4B49C
$pinkLight: #F6C9B6
$greenLight: #D1CABB
$blueLight: #67778B

$beige: #f5f5f5
$beigeDark: #f5f5f595

/*** fonts ***/
/* fonts familie s*/

$fontIkea: "Noto Sans", sans-serif
$fontHandWritten: "Indie Flower", cursive
$fontApple: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen

/*fonts style*/
$Light: 100
$Regular: 300
$Bold: 800

/* sizes */

$header-height: 70px
$footer-height: 85px

$mob-button-height: 44px

/* animation */

@keyframes pop
  0%
    filter: hue-rotate(30deg)

  100%
    filter: hue-rotate(0deg)
