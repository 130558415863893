.section-portfolio
  li
    .card
      display: grid

      width: 150px
      grid-template-rows: 140px
      padding: 0
      filter: grayscale(0.7)
      perspective: 1000px

      &:hover
        .card-inner
          transform: rotateY(180deg)

      .card-inner
        position: relative

        width: 100%
        height: 100%
        text-align: center
        transition: transform 0.5s
        transform-style: preserve-3d

      .card-front,.card-back
        position: absolute
        width: 100%
        height: 100%
        -webkit-backface-visibility: hidden
        backface-visibility: hidden

      .card-back
        transform: rotateY(180deg)
        background-color: $pink

        display: grid
        align-content: center
        justify-content: center
        border-radius: .6em

        padding: 2em
        h3
          color: $white
        .hover-link
          position: absolute
          bottom: 10px
          right: 10px
          color: white

      @media only screen and (min-width: $desktopBreakpoint)
        grid-template-rows: 120px
        margin: 0
        padding: 0

      .card-img
        position: relative
        height: 100%
        width: 100%
        border-radius: .6em
        overflow: hidden

        img
          margin: 0
          padding: 0
          width: 100%
          height: 100%
          overflow: hidden
          object-fit: cover
          transform: scale(1)
          filter: brightness(.9)

  .projects-lists
    li:nth-child(2n+2) .card .card-inner
      .card-back
        background: $green
    li:nth-child(3n) .card .card-inner
      .card-back
        background: $lightBlue

.section-portfolio .wrapper .card-overlay
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  background: rgba(255,255 ,255 ,.8 )

  z-index: 10
  display: flex
  flex-direction: column
  justify-content: center
  cursor: default

  .comingsoon
    text-align: center
    z-index: 1000
    margin: 0
    padding: 0
    height: 100%
    width: 100%
    height: min-content
    font-weight: 600
    text-shadow: 1px 1px 2px rgba(0,0 ,0 ,.2 )
    animation: fadein 2s infinite
