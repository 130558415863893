.accordion-positionner
  position: relative

.tag
  position: absolute
  top: -$header-height - 16px

.accordion
  border-radius: .5em
  overflow: hidden
  @include shadow-light-accordion

  .accordion-title,.accordion-content
    background: $beigeDark

  .accordion-title
    display: flex
    justify-content: space-between
    align-items: center

    color: $gray5
    cursor: pointer
    padding: 18px
    width: 100%
    height: 100%

    *
      transition: .3s ease

    &:hover
      background: #eeeeee

      h3
        transform: translateX(15px)
        transform-origin: center left
        transition: .3s ease
      .accordion-icon
        transform-origin: center center

        transition: .3s ease

  .accordion-content

    border-top: 1px solid #00000010
    overflow: hidden
    padding: 0 18px

// animate
// accordion closed
.accordion-content[aria-expanded="false"]
  border-top-color: transparent
  max-height: 0px
  opacity: .3
  transition: all .3s ease-out

// accordion opened
.accordion-content[aria-expanded="true"]
  max-height: 3000px
  opacity: 1
  transition: max-height 1s ease-in-out
