.about-content
  display: grid
  row-gap: 1.5em
  align-items: center
  justify-items: center
  grid-template-rows: min-content 1fr
  grid-template-columns: 1fr
  grid-template-areas: " img" "paragraph"
  padding: 1em
  @media only screen and (min-width: $tabletBreakpoint)
    grid-template-rows: 1fr
    grid-template-columns: 1fr 1fr
    grid-template-areas: "paragraph img"
    justify-items: flex-end
    padding: 2em 5em 3em 3em

.about-paragraph
  grid-area: paragraph
  max-width: 500px
  border-left: 1em solid $grayLight
  padding-left: 2em

.about-img
  grid-area: img
  width: 250px
  height: 250px
  border-radius: 250px
  @media only screen and (min-width: $tabletBreakpoint)
    width: 200px
    height: 200px
.additional-content
  padding: 1em
