@import ./_variables
.card-edu,.card-exp
    transition: .3s
    border-radius: .3em
    padding-bottom: 1em

    &:hover
        transition: .3s
        box-shadow: 2px 2px 4px #00000030
        transform: scale(1.01)

        box-shadow: 2px 2px 4px rgba(0,0,0 ,.3)
    .list-item
        padding-left: 2.5em

.card-exp
    background: linear-gradient(0.25turn, $blueLight, #4086be)

    display: grid
    grid-auto-flow: row
    grid-gap: .3em
    padding-bottom: 1em7
    transition: .3s

    .title

        height: min-content
        display: flex
        align-items: center
        justify-content: space-between
        padding-top: 1em

    p,h3,h4

        position: relative
        color: $white
        padding: 0 32px

    .description p
        font-weight: 200
    .hover-link
        position: absolute
        bottom: 10px
        right: 10px
        opacity: 0
        color: white

    &:hover
        .hover-link
            transition: .5s ease-in
            opacity: 0.7

.card-edu
    background: linear-gradient(0.25turn, $pink, $pinkLight)
    position: relative
    display: grid
    grid-auto-flow: row
    grid-gap: .3em
    padding-bottom: 1em
    transition: .3s

    .title
        border-radius: 0 16px 0 0
        height: min-content
        display: flex
        align-items: center
        justify-content: space-between
        padding-top: 1em
        @media only screen and (max-width: $tabletBreakpoint)
            display: block

    p,h3,h4
        position: relative
        color: $gray3
        padding: 0 32px

    .description p
        font-weight: 200
    .hover-link
        position: absolute
        bottom: 10px
        right: 10px
        opacity: 0

    &:hover
        .hover-link
            transition: .5s ease-in
            opacity: 0.7

.card-cont

    display: grid
    grid-auto-flow: row
    grid-gap: .3em

    p,h4
        position: relative

        display: flex
        align-items: center

        &:hover
            color: $yellow

        &::before
            content: (">")
            top: 5px
            margin-right: 16px
            font-weight: 900
            font-size: 24px

.card-skills

    display: grid
    grid-auto-flow: row
    gap: .3em
    overflow: hidden
    border-radius: .3em

    background: linear-gradient(0.75turn, #e2c15d, #c0a242)
    box-shadow: 2px 2px 4px rgba(0,0,0,.1)

    p,h4
        position: relative
        color: $white

        display: flex
        align-items: center
        padding: 1em
